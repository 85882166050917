<template>
  <content justify="center">
    <!-- DIALOGO RESPUESTA SALIDA PEDIDOS -->
    <v-dialog
      persistent
      v-model="dialogoRespuestaSalida"
      max-width="1200"
      scrollable
    >
      <v-card>
        <v-card-title class="info lighten-1 white--text">
          <strong>TOTAL PEDIDOS: {{ totalPedidos }}</strong>
          <v-progress-circular
            class="ml-2"
            indeterminate
            :color="colorProgreso"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text style="height: 400px">
          <v-row>
            <v-col>
              <v-simple-table dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No</th>
                      <th class="text-left">Pedido</th>
                      <th class="text-left">Estado THOR</th>
                      <th class="text-left">Repartidor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in resultadoSalidas"
                      :key="item.orderId"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.orderId }}</td>
                      <td>{{ item.estadoThor }}</td>
                      <td>{{ item.rayo }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Estado WMS</th>
                      <th class="text-left">Observación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in resultadoInventory" :key="item.orderId">
                      <td>{{ item.estadoInventory }}</td>
                      <td>{{ item.observacion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            class="mt-2"
            color="error"
            @click="dialogoRespuestaSalida = false"
            >Cerrar</v-btn
          >
          <v-btn
            class="mt-2 ml-5"
            color="success"
            @click="sacarRuta"
            :disabled="btnSacarRuta"
            >Sacar ruta</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIN DIALOGO RESPUESTA SALIDA PEDIDOS -->

    <v-form ref="enviarFormulario" v-model="validar">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" sm="8" md="7" lg="7" xl="7">
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <h3>
                <v-icon size="50" color="info"
                  >mdi-arrow-u-left-bottom-bold</v-icon
                >
                Salida rutas
              </h3>
            </v-col>
          </v-row>

          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <v-autocomplete
                :items="listaRayos"
                item-value="id"
                v-model="rayoSeleccionado"
                item-text="text"
                label="Selecciona Rayo"
                solo
                :rules="rayoRules"
                :loading="cargandoListaRayos"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <v-autocomplete
                :items="posiciones"
                item-value="id"
                v-model="posicionSeleccionada"
                item-text="nombre_posicion"
                label="Selecciona posición"
                solo
                :rules="posicionesRules"
                :loading="cargandoPosiciones"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" sm="8" md="5" lg="5">
          <v-textarea
            ref="buscador"
            v-model="listaPedidos"
            outlined
            name="input-7-4"
            label=""
            value=""
            required
            :rules="pedidosRules"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              @click="validarAsignaciones"
              color="success"
              :disabled="!validar"
            >
              Validar asignación
            </v-btn>
            <v-btn class="ml-4" color="warning" @click="limpiarDatos">
              Limpiar datos
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </content>
</template>

<script>
import axios from "axios";
import firebase from "firebase";

export default {
  data() {
    return {
      btnSacarRuta: true,
      posicionesRules: [(v) => !!v || "Debes seleccionar posicion"],
      posiciones: [],
      totalPedidos: null,
      posicionSeleccionada: "",
      cargandoPosiciones: false,
      rayoRules: [(v) => !!v || "Debes seleccionar Rayo"],
      pedidosRules: [(v) => !!v || "Debes ingresar al menos un pedido"],
      listaRayos: [],
      rayoSeleccionado: "",
      listaPedidos: "",
      dialogoRespuestaSalida: false,
      colorProgreso: "",
      validar: false,
      cargandoListaRayos: false,
      fechaActual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      itemsFallidos: [],
      resultadoSalidas: [],
      resultadoInventory: [],
      arrayRutaValidada: null,
      estados: [
        {
          _id: "5f05f3f77f5164494c74b96a",
          number_status: 5,
          name_status: "En progreso",
        },
        {
          _id: "5f05f3dc7f5164494c74b968",
          number_status: 3,
          name_status: "Aceptado",
        },
        {
          _id: "5f05f3d37f5164494c74b967",
          number_status: 2,
          name_status: "Asignado",
        },
        {
          _id: "5f05f4217f5164494c74b96d",
          number_status: 8,
          name_status: "Rechazado",
        },
        {
          _id: "5f05f3607f5164494c74b966",
          number_status: 1,
          name_status: "No asignado",
        },
        {
          _id: "5f05f4147f5164494c74b96c",
          number_status: 7,
          name_status: "Fallido",
        },
        {
          _id: "5f05f3eb7f5164494c74b969",
          number_status: 4,
          name_status: "Iniciado",
        },
        {
          _id: "5f05f4077f5164494c74b96b",
          number_status: 6,
          name_status: "Exitoso",
        },
        {
          _id: "5f05f42e7f5164494c74b96e",
          number_status: 9,
          name_status: "Cancelado",
        },
        {
          _id: "60711a2e476d98a0944ca9f0",
          name_statu: "Silent Arrival",
          number_status: 20,
        },
        {
          _id: "60bfdb431b02612234560b16",
          number_status: 30,
          name_status: "Devuelto",
          __v: 0,
        },
      ],
    };
  },
  computed: {
    async obtenerRayos() {
      try {
        console.log("Obteniendo Rayos...");
        this.cargandoListaRayos = true;
        let arrayFleets = [];
        const listaRayos = await axios.get(
          "https://backendservices.rayoapp.com/Rayos/rayos/reduced",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );

        for (const n in listaRayos.data) {
          arrayFleets.push({
            text: listaRayos.data[n].name_rayo,
            id: listaRayos.data[n].id,
          });
        }

        console.log(listaRayos.status);

        listaRayos.status == 200
          ? console.log("Lista creada!")
          : console.log("No se logró obtener la lista! Revisar API");

        arrayFleets.sort((b, a) => b.text.localeCompare(a.text));
        this.listaRayos = arrayFleets;

        this.cargandoListaRayos = false;
      } catch (error) {
        this.cargandoListaRayos = false;
        console.log(error);
      }
    },
    async obtenerPosiciones() {
      try {
        console.log("Obteniendo posiciones...");
        this.cargandoPosiciones = true;
        let arregloPosiciones = [];

        const posiciones = await axios.get(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/posiciones"
        );
        console.log(posiciones.status);

        for (const n in posiciones.data) {
          arregloPosiciones.push({
            nombre_posicion: posiciones.data[n].name_position,
            id: posiciones.data[n]._id,
          });
        }
        // console.log(arregloPosiciones);
        this.posiciones = arregloPosiciones;
        console.log("Lista creada!");

        this.cargandoPosiciones = false;
      } catch (error) {
        this.cargandoPosiciones = false;
        console.log(error);
      }
    },
  },

  methods: {
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
    limpiarDatos() {
      this.$refs.enviarFormulario.reset();
    },
    validarFormulario() {
      this.$refs.enviarFormulario.validate();
    },
    async validarAsignaciones() {
      try {
        this.btnSacarRuta = true;
        this.colorProgreso = "white";
        this.dialogoRespuestaSalida = true;
        this.resultadoSalidas = [];
        this.resultadoInventory = [];
        console.log("Validando asignaciones...");
        var arreglo_pedidos = this.listaPedidos.split(/\r\n|\n|\r/);
        this.arrayRutaValidada = arreglo_pedidos;
        this.totalPedidos = arreglo_pedidos.length;

        for (const i in arreglo_pedidos) {
          if (arreglo_pedidos[i] != "") {
            const consultaPedido = await axios.get(
              `https://backendservices.rayoapp.com/Task-Groups/tasks_group/aux/by?idOrder=${arreglo_pedidos[i]}`,
              {
                headers: {
                  "warehouse.rayoapp.com": "rayo",
                },
              }
            );

            // console.log(consultaPedido.data);
            if (consultaPedido.data.length == 0) {
              // console.log("Pedido no THOR");
              let resultado = {
                orderId: arreglo_pedidos[i].trim(),
                estadoThor: "No está en THOR",
                rayo: "--",
              };
              this.resultadoSalidas.push(resultado);
            } else {
              let tareas = consultaPedido.data[0].tasks;

              console.log(tareas);
              //console.log(tareas);
              let estado_thor = "";
              let rayo = null;

              //console.log("Array: " + tareas.length);

              if (tareas[tareas.length - 1].type_task == 2) {
                //console.log("Es delivery?");
                if (
                  tareas[tareas.length - 1].idstatus_task ==
                    "5f05f3d37f5164494c74b967" ||
                  tareas[tareas.length - 1].idstatus_task ==
                    "5f05f3dc7f5164494c74b968"
                ) {
                  estado_thor = "Asignado";
                  rayo =
                    consultaPedido.data[0].tasks[tareas.length - 1].idrayo_task;
                } else {
                  estado_thor = "No asignado";
                }
              } else if (tareas[tareas.length - 2].type_task == 2) {
                //console.log("Es delivery 2?");
                if (
                  tareas[tareas.length - 2].idstatus_task ==
                    "5f05f3d37f5164494c74b967" ||
                  tareas[tareas.length - 2].idstatus_task ==
                    "5f05f3dc7f5164494c74b968"
                ) {
                  estado_thor = "Asignado";
                  rayo =
                    consultaPedido.data[0].tasks[tareas.length - 2].idrayo_task;
                } else {
                  estado_thor = "No asignado";
                }
              } else {
                //console.log("No hay delivery");
                estado_thor = "Pedido sin delivery para asignar";
              }

              let nombre_rayo = await this.nombreRayo(rayo);

              let resultado = {
                orderId: `${arreglo_pedidos[i]}`,
                estadoThor: `${estado_thor}`,
                rayo: `${nombre_rayo}`,
              };
              this.resultadoSalidas.push(resultado);
            }
          }
        }
        this.colorProgreso = "info lighten-1";
        //console.log(this.resultadoSalidas);
      } catch (error) {
        console.log(error);
      }

      this.btnSacarRuta = false;
    },

    nombreRayo(id) {
      for (const i in this.listaRayos) {
        if (id == this.listaRayos[i].id) {
          return this.listaRayos[i].text;
        }
      }
      return "--";
    },

    async sacarRuta() {
      try {
        this.colorProgreso = "white";
        //this.dialogoRespuestaSalida = true;
        //this.resultadoSalidas = [];
        var arreglo_pedidos = this.arrayRutaValidada;
        var estado_name = "Collected";
        var estado_id = "608713be05bfeb80c52f5efd";
        var posicion_name = "";
        var posicion_id = this.posicionSeleccionada;
        var rayo = "";
        var email = this.datosUsuario().email_user;
        var rayo_id = this.rayoSeleccionado;

        var nuevoArreglo = [];

        for (const i in this.posiciones) {
          if (this.posicionSeleccionada == this.posiciones[i].id) {
            posicion_name = this.posiciones[i].nombre_posicion;
          }
        }

        for (const i in this.listaRayos) {
          if (this.rayoSeleccionado == this.listaRayos[i].id) {
            rayo = this.listaRayos[i].text;
          }
        }

        console.log("CANT DE PEDIDOS: " + arreglo_pedidos.length);

        for (var j = 0; j < arreglo_pedidos.length; j++) {
          var formData = {
            codigo: arreglo_pedidos[j].trim(),
            estado: estado_id,
            estado_name: estado_name,
            posicion_name: posicion_name,
            posicion: posicion_id,
            email: email,
            rayo: rayo,
            _id: rayo_id,
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
          };

          nuevoArreglo.push(formData);
        }
        console.log(nuevoArreglo);

        console.log("Sacando rutas...");

        var respuestaPeticion = await axios.post(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/collected",

          nuevoArreglo
        );
        console.log(respuestaPeticion.status);
        console.log("LO QUE RECIBO: ", respuestaPeticion.data);

        for (const l in respuestaPeticion.data) {
          //var order_id = respuestaPeticion.data[l].order_id;
          var estado = respuestaPeticion.data[l].estado;
          var observacion = respuestaPeticion.data[l].observacion;

          let resultado = {
            //orderId: `${order_id}`,
            estadoInventory: `${estado}`,
            //orderId: `${order_id}`,
            observacion: `${observacion}`,
          };
          this.resultadoInventory.push(resultado);
        }
        this.arrayRutaValidada = null;
        this.colorProgreso = "info lighten-1";
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.obtenerRayos;
    this.obtenerPosiciones;
    this.datosUsuario();
  },
};
</script>
